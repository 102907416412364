/* eslint-disable @nx/enforce-module-boundaries */
import moment from 'moment';
import { useContext } from 'react';
import { PostBoxContext } from '../context';
import { Text } from '@mybridge/ui/text';

const PostTiming = () => {
  const { post } = useContext(PostBoxContext);
  const { type, created_at } = post || {};
  if (type === 2) {
    return (
      <Text fontSize="xs" color="brandGray.700">
        {moment(new Date(created_at)).fromNow()}
      </Text>
    );
  }

  return (
    <Text fontSize="xs" color="brandGray.700">
      {moment(created_at).startOf().fromNow()}
    </Text>
  );
};

export default PostTiming;
