import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Radio, RadioGroup } from '@mybridge/ui/radio';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo, useState } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { PostBoxContext } from '../../context';
import { List, ListItem, UnorderedList } from '@mybridge/ui/list';

export const DeleteChildPost = ({handleRemove,onClose}) => {


  return (
    <>
      <Modal isOpen={true} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Delete thread?
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>This can't be undone and it will be removed from:</Text>
              </Stack>
              <UnorderedList>
                <Stack spacing={4}>
                  <ListItem>Your profile</ListItem>
                  <ListItem>
                    The timeline of any accounts that follow you
                  </ListItem>
                  <ListItem>From myBridge search results</ListItem>
                </Stack>
              </UnorderedList>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleRemove}
                colorScheme="red"
                // isLoading={deletePostLoading}
              >
                Remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
