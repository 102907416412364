import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Textarea } from '@mybridge/ui/text-area';
import { useContext, useState } from 'react';
import { PostBoxContext } from '../../context';

export const PostBoxNotInterestedModal = ({ ...props }) => {
  const [comment, setComment] = useState();

  const { post, ignorePost, ignorePostLoading } = useContext(PostBoxContext);

  const handleIgnorePost = () => {
    const payload = {
      id: post?.id,
    };
    ignorePost(payload);
  };
  return (
    <>
      <Modal {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Not interested?
          </ModalHeader>
          <ModalBody>
            <Textarea
              value={comment}
              onChange={(e) => setComment(e?.target?.value)}
              placeholder="Optional Comment..."
            />
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                isLoading={ignorePostLoading}
                onClick={handleIgnorePost}
                variant="primary"
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
